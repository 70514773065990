import React from 'react';
import AddFlightForm from '../components/Flights/AddFlightForm.component';
import Header from '../generic/Header.generic';

const AddFlight = () => {
  return (
    <div className="page">
      <Header from="add_flight" backUrl="/flights" title="Add Flight"></Header>
      <AddFlightForm></AddFlightForm>
    </div>
  );
};

export default AddFlight;
