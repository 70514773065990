import React from 'react';

type Props = {
  label?: string;
  onClick?: any;
  customClass?: any;
};

const Button = (props: Props) => {
  return (
    <button onClick={props.onClick} className={props.customClass}>
      {props.label}
    </button>
  );
};

export default Button;
