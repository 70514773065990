import React from 'react';

type Props = {
  onClick?: any;
  customClass?: string;
  isWhite?: boolean;
};

const BackButton = (props: Props) => {
  return <img onClick={props.onClick} src={props.isWhite ? 'img/arrow_white.svg' : 'img/arrow.svg'} alt="Back Button" className={props.customClass} />;
};

export default BackButton;
