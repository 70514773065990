import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Context/User.context';
import Button from '../generic/Button.generic';
import InputWithLabel from '../generic/InputWithLabel.generic';
import Link from '../generic/Link.generic';
import { login, refreshToken } from '../services/User.service';
import jwt from 'jwt-decode';
import { getCookie, setCookie, showErrorToast, showSuccessToast } from '../services/Utils.service';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { setAccessToken, resetContext } = useContext<any>(UserContext);
  const { setUser } = useContext<any>(UserContext);
  const navigate = useNavigate();

  const signIn = () => {
    login({ email, password }).then(
      (result: any) => {
        setCookie('accessToken', result.data.accessToken);
        setCookie('userMail', email);
        setCookie('refreshToken', result.data.refreshToken);
        setUser(jwt(result.data.accessToken));
        setAccessToken(result.data.accessToken);
        showSuccessToast('Connection Complete.');
        navigate('/dashboard');
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
  };

  useEffect(() => {
    if (getCookie('refreshToken') && getCookie('refreshToken') != '' && getCookie('userMail') && getCookie('userMail') != '') {
      refreshToken()
        .then((result: any) => {
          setCookie('accessToken', result.data.accessToken);
          setCookie('refreshToken', result.data.refreshToken);
          setUser(jwt(result.data.accessToken));
          setAccessToken(result.data.accessToken);
          navigate('/dashboard');
        })
        .catch(error => {
          console.error('an error occured while trying to refresh the token');
        });
    }
    if (getCookie('userMail') && getCookie('userMail') != '') {
      setEmail(getCookie('userMail'));
    }
    if (getCookie('accessToken') && getCookie('accessToken') != '') {
      let infos: any = jwt(getCookie('accessToken'));
      let date = new Date();
      if (date < new Date(infos.exp * 1000)) {
        setEmail(getCookie('userMail'));
        showSuccessToast('Connection restored.');
        setAccessToken(getCookie('accessToken'));
        setUser(infos);
        navigate('/dashboard');
      }
    }
  }, []);

  return (
    <div className="page">
      <img src="img/cps_logo.png" alt="Logo CPS" className="logo_login mt-10" />
      <h1 className="title_form mt-10">Sign In</h1>
      <InputWithLabel
        type="email"
        label="Email"
        value={email}
        placeholder="Enter email"
        customClass="basic_input mt-1"
        customClassLabel="input_label mt-5"
        onChange={(e: any) => setEmail(e.target.value)}></InputWithLabel>
      <InputWithLabel
        type="password"
        label="Password"
        value={password}
        placeholder="************"
        customClass="basic_input mt-1"
        customClassLabel="input_label mt-5"
        onChange={(e: any) => setPassword(e.target.value)}></InputWithLabel>
      <Link
        onClick={() => {
          navigate('/forget_password');
        }}
        label="Forgot your password ?"
        customClass="basic_link mt-5"></Link>
      <Button onClick={() => signIn()} label="Login" customClass="basic_button mt-5"></Button>
      <label className="mt-10 simple_text">Version : 0.0.1</label>
    </div>
  );
};

export default Login;
