import React, { useContext } from 'react';
import { UserContext } from '../Context/User.context';
import { formatDate, formatReadableHourToMinutes, formatMinutesToReadableHour, getTimeOutFromCampHour, getTimeInToCampHour } from '../services/Utils.service';

type Props = {
  customClass?: string;
  data: Array<any>;
  icon?: string;
  onClick?: any;
};

const List = (props: Props) => {
  const { user } = useContext<any>(UserContext);

  const getClassByStatus = (flight: any) => {
    const date = new Date();
    // const splitedFlightDate = flight.date.split('/');
    let dateFlight = new Date(flight.date);
    // const flightDate = new Date(`${dateFlight.getMonth() + 1}/${date.getFullYear()}/${date.getDate()}`);
    const flightDate = dateFlight;
    if (flight?.flightStatusId === 3) {
      return 'cancelled-flight';
    } else {
      if (flightDate >= new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate())) {
        let takeOff = formatMinutesToReadableHour(flight.takeoffTimeReal)?.split(':');
        let landing = formatMinutesToReadableHour(flight.landingTimeReal)?.split(':');
        let flightToday =
          flightDate.getFullYear() == date.getFullYear() && flightDate.getMonth() + 1 == date.getMonth() + 1 && flightDate.getDate() == date.getDate();
        if (flightDate >= new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate())) {
          if (flightToday && landing[0] != '--' && landing[1] != '--') {
            return 'past-flight';
          } else {
            if (flightToday && (+takeOff[0] < date.getHours() || (+takeOff[0] == date.getHours() && +takeOff[1] <= date.getMinutes()))) {
              return 'in-flight';
            } else {
              if (flight.createDateTime !== flight.lastChangedDateTime) {
                return 'updated-flight';
              } else {
                return '';
              }
            }
          }
        }
      } else {
        if (flight.landingTimeReal != null) {
          return 'past-flight';
        } else {
          return '';
        }
      }
    }
  };

  const getDateOfFlight = (flight: any) => {
    let date = new Date(flight?.date);
    return `${date.getDate()}-${
      date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    }-${date.getFullYear()} ${formatMinutesToReadableHour(flight.takeoffTimeRally)}`;
  };

  return (
    <ul className={props.customClass}>
      {props.data?.map((f: any, key) => (
        <li key={'Flight_' + key} className={`${getClassByStatus(f)}`} onClick={() => (user?.roleName !== 'PASSENGERS' ? props?.onClick(f?.id) : null)}>
          <div className="list_item_block_icon">
            <span className="name_flight pt-2">{f?.mission?.name}</span>
            <img src={props.icon} className="icon_item_list" />
          </div>
          <div className="list_item_info">
            {user?.roleName !== 'PASSENGERS' ? <span className="info_flight">DATE : {getDateOfFlight(f)}</span> : <></>}
            <span className="info_flight">FROM : {f?.departureAirport?.civilCode}</span>
            <span className="info_flight">TO : {f?.arrivalAirport?.civilCode}</span>
          </div>
          {user?.roleName === 'PASSENGERS' ? (
            <div className="list_item_info">
              <span className="info_flight">DEPARTURE FROM CAMP: </span>
              <span className="info_flight"> {getTimeOutFromCampHour(f)}</span>
              <span className="info_flight">ARRIVAL TO CAMP : </span>
              <span className="info_flight"> {getTimeInToCampHour(f)}</span>
            </div>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default List;
