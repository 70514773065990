import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from './BackButton.generic';

type Props = {
  backUrl: string;
  title?: string;
  from?: string;
  noProfileIcon?: boolean;
};

const Header = (props: Props) => {
  const navigate = useNavigate();

  return (
    <div className="header">
      <BackButton customClass="back_button_header" isWhite={true} onClick={() => navigate(props.backUrl)}></BackButton>
      <h1 className="title">{props.title}</h1>
      {!props.noProfileIcon ? (
        <img onClick={() => navigate(`/profile?from=${props?.from ? props.from : '/'}`)} src="img/profile.svg" alt="Profile Icon" className="profile_icon" />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Header;
