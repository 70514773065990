import React, { createContext, useState } from 'react';
import { getCookie, setCookie } from '../services/Utils.service';

const UserContext = createContext({});

export const getToken = () => {
  return getCookie('accessToken');
};

function UserProvider({ children }: any) {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [user, setUser] = useState<any | null>(null);
  const [selectedDateFlight, setSelectedDateFlight] = useState<string>('');

  const resetContext = () => {
    setAccessToken(null);
    setUser(null);
    setSelectedDateFlight('');
    setCookie('accessToken', '');
    setCookie('refreshToken', '');
  };

  return (
    <UserContext.Provider
      value={{
        accessToken,
        setAccessToken: (token: string) => setAccessToken(token),
        user,
        setUser: (user: any) => setUser(user),
        selectedDateFlight,
        setSelectedDateFlight: (date: string) => setSelectedDateFlight(date),
        resetContext: () => resetContext(),
      }}>
      {children}
    </UserContext.Provider>
  );
}
export { UserContext, UserProvider };
