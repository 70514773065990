import React from 'react';

type Props = {
  type?: string;
  value?: any;
  onChange?: any;
  customClass?: string;
  min?: number;
  max?: number;
  id?: string;
  placeholder?: string;
  isTextArea?: boolean;
  readonly?: boolean;
};

const Input = (props: Props) => {
  return (
    <>
      {!props.isTextArea ? (
        <input
          id={props.id}
          type={props.type}
          value={props.value}
          readOnly={props.readonly}
          onChange={props.onChange}
          className={props.customClass}
          min={props.min}
          placeholder={props.placeholder}
          max={props.max}></input>
      ) : (
        <textarea
          cols={4}
          readOnly={props.readonly}
          id={props.id}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          className={props.customClass}></textarea>
      )}
    </>
  );
};

export default Input;
