import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CrewForm from '../components/Flights/CrewForm.component';
import DispatchForm from '../components/Flights/DispatchForm.component';
import { UserContext } from '../Context/User.context';
import Header from '../generic/Header.generic';
import { getFlightById } from '../services/Flight.service';
import { showErrorToast } from '../services/Utils.service';

const FlightInfo = () => {
  const { user } = useContext<any>(UserContext);
  const [flight, setFlight] = useState<any>(null);
  const { search } = useLocation();

  useEffect(() => {
    let flightId = search.replace('?', '').split('=')[1];
    getFlightById(+flightId).then(
      (result: any) => {
        if (result.data) {
          setFlight(result.data);
        } else {
          showErrorToast('An error occured while fetching the flight details');
        }
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
  }, []);

  return (
    <div className="page">
      <Header from="flights" backUrl="/flights" title="Flights"></Header>
      {user?.roleName === 'CREW' ? <CrewForm data={flight}></CrewForm> : <DispatchForm data={flight}></DispatchForm>}
    </div>
  );
};

export default FlightInfo;
