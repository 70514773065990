import React from 'react';

type Props = {
  customClass?: string;
  type?: string;
  remark?: string;
  onDelete?: any;
  id?: number;
};

const CardDelay = (props: Props) => {
  return (
    <div className={`card_delay ${props.customClass}`}>
      <img src="/img/trash_icon_red.svg" className="icon_trash" onClick={props.onDelete} />
      <span className="type">{props.type}</span>
      <p className="remark">{props.remark}</p>
    </div>
  );
};

export default CardDelay;
