import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddFlight from '../pages/AddFlight.page';
import Dashboard from '../pages/Dashboard.page';
import FlightDetail from '../pages/FlightDetail.page';
import FlightInfo from '../pages/FlightInfo.page';
import Flights from '../pages/Flights.page';
import Login from '../pages/Login.page';
import Planning from '../pages/Planning.page';
import Profile from '../pages/Profile.page';
import ResetPassword from '../pages/ResetPassword.page';

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/forget_password" element={<ResetPassword />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/planning" element={<Planning />}></Route>
        <Route path="/detail_flight" element={<FlightDetail />}></Route>
        <Route path="/flights" element={<Flights />}></Route>
        <Route path="/flight_info" element={<FlightInfo />}></Route>
        <Route path="/add_flight" element={<AddFlight />}></Route>
      </Routes>
    </Router>
  );
};

export default Routing;
