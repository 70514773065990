import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../generic/Button.generic';
import CardDelay from '../../generic/CardDelay.generic';
import FloatingButton from '../../generic/FloatingButton.generic';
import InputWithLabel from '../../generic/InputWithLabel.generic';
import SelectWithLabel from '../../generic/SelectWithLabel.generic';
import { getAircraft } from '../../services/Aircraft.service';
import { getAirports } from '../../services/Airports.service';
import { add, del, get, getType } from '../../services/Delay.service';
import { edit, getFlightStatus } from '../../services/Flight.service';
import { getMissions } from '../../services/Mission.service';
import { formatDateInput, formatMinutesToReadableHour, formatReadableHourToMinutes, showErrorToast, showSuccessToast } from '../../services/Utils.service';

type Props = {
  data?: any;
};

const DispatchForm = (props: Props) => {
  const [inEdit, setInEdit] = useState<boolean>(false);
  const [flightInEdit, setFlightInEdit] = useState<any>(props.data);
  const [toUpdate, setToUpdate] = useState<any>({});
  const [airports, setAirports] = useState<any>([]);
  const [delayType, setDelayType] = useState<any>([]);
  const [delay, setDelay] = useState<any>([]);
  const [missions, setMissions] = useState<any>([]);
  const [aircrafts, setAircrafts] = useState<any>([]);
  const [flightStatus, setFlightStatus] = useState<any>([]);
  const [currentDelay, setCurrentDelay] = useState<string>('');
  const [currentRemark, setCurrentRemark] = useState<any>('');
  const navigate = useNavigate();

  useEffect(() => {
    getAirports().then(
      (result: any) => {
        setAirports(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
    getMissions().then(
      (result: any) => {
        setMissions(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
    getAircraft().then(
      (result: any) => {
        setAircrafts(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
    getFlightStatus().then(
      (result: any) => {
        setFlightStatus(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
    getType().then(
      (result: any) => {
        setDelayType(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
  }, []);

  useEffect(() => {
    setFlightInEdit(JSON.parse(JSON.stringify(props.data)));
    if (props?.data?.id) {
      get(props?.data?.id).then(
        (result: any) => {
          setDelay(result.data);
        },
        (error: any) => {
          showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
        },
      );
    }
  }, [props.data]);

  const updateFlight = (value: any, field: string) => {
    switch (field) {
      case 'date':
        flightInEdit.date = value;
        toUpdate.date = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'departureAirport':
        flightInEdit.departureAirportId = value;
        toUpdate.departureAirportId = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'takeoff':
        flightInEdit.takeoffTimeUtc = formatReadableHourToMinutes(value);
        toUpdate.takeoffTimeUtc = formatReadableHourToMinutes(value);
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'takeoffTimeReal':
        flightInEdit.takeoffTimeReal = formatReadableHourToMinutes(value);
        toUpdate.takeoffTimeReal = formatReadableHourToMinutes(value);
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'arrivalAirport':
        flightInEdit.arrivalAirportId = value;
        toUpdate.arrivalAirportId = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'landingTimeUtc':
        flightInEdit.landingTimeUtc = formatReadableHourToMinutes(value);
        toUpdate.landingTimeUtc = formatReadableHourToMinutes(value);
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'takeoffTimeTlb':
        flightInEdit.takeoffTimeTlb = formatReadableHourToMinutes(value);
        toUpdate.takeoffTimeTlb = formatReadableHourToMinutes(value);
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'landingTimeTlb':
        flightInEdit.landingTimeTlb = formatReadableHourToMinutes(value);
        toUpdate.landingTimeTlb = formatReadableHourToMinutes(value);
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'landingTimeReal':
        flightInEdit.landingTimeReal = formatReadableHourToMinutes(value);
        toUpdate.landingTimeReal = formatReadableHourToMinutes(value);
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'fuelOnDeparture':
        flightInEdit.fuelOnDeparture = value;
        toUpdate.fuelOnDeparture = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'fuelOnArrival':
        flightInEdit.fuelOnArrival = value;
        toUpdate.fuelOnArrival = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'flightStatus':
        flightInEdit.flightStatusId = value;
        toUpdate.flightStatusId = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'passengerPlanned':
        flightInEdit.passengerPlanned = value;
        toUpdate.passengerPlanned = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'passengerCounter':
        flightInEdit.passengerCounter = value;
        toUpdate.passengerCounter = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'mission':
        flightInEdit.missionId = value;
        toUpdate.missionId = value;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      case 'aircraft':
        flightInEdit.aircraftId = value;
        toUpdate.aircraftId = value;
        flightInEdit.passengerCapacity = aircrafts.filter((a: any) => a.id == value)[0].passengerCapacity;
        toUpdate.passengerCapacity = aircrafts.filter((a: any) => a.id == value)[0].passengerCapacity;
        setToUpdate(JSON.parse(JSON.stringify(toUpdate)));
        break;
      default:
        break;
    }
    setFlightInEdit(JSON.parse(JSON.stringify(flightInEdit)));
  };

  const saveChange = () => {
    edit(toUpdate, flightInEdit.id).then(
      (result: any) => {
        if (result?.status === 200) {
          showSuccessToast('Flight updated successfully');
          navigate('/flights');
        }
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
    setInEdit(false);
  };

  const addRemark = () => {
    add({ remark: currentRemark, delayTypeId: currentDelay, flightId: flightInEdit.id }).then(
      (result: any) => {
        showSuccessToast('Delay created successfully');
        setDelay([...delay, result.data]);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
  };

  const deleteDelay = (id: number) => {
    del(id).then(
      (result: any) => {
        setDelay([...delay.filter((d: any) => d.id != id)]);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
  };

  return (
    <>
      {flightInEdit ? (
        <div className="form_details">
          <h1 className="flight_name">
            {inEdit ? '(Edit Mode)' : ''} Aircraft : {flightInEdit?.aircraft.reg}
          </h1>
          <InputWithLabel
            type="date"
            label="Date"
            readonly={!inEdit}
            value={formatDateInput(flightInEdit?.date)}
            onChange={(e: any) => updateFlight(e.target.value, 'date')}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <SelectWithLabel
            label="Departure Airport"
            customClass="basic_select mt-1"
            id="select_airport"
            data={airports}
            isReadOnly={!inEdit}
            currentValue={flightInEdit?.departureAirportId}
            value="id"
            onChange={(e: any) => updateFlight(e.target.value, 'departureAirport')}
            labelOption="civilCode"
            customClassLabel="input_label mt-3"></SelectWithLabel>
          <InputWithLabel
            type="time"
            label="Take off time UTC"
            readonly={!inEdit}
            value={formatMinutesToReadableHour(flightInEdit?.takeoffTimeUtc)}
            onChange={(e: any) => updateFlight(e.target.value, 'takeoff')}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <InputWithLabel
            type="time"
            label="Act Take Off"
            readonly={!inEdit}
            value={formatMinutesToReadableHour(flightInEdit?.takeoffTimeReal)}
            onChange={(e: any) => updateFlight(e.target.value, 'takeoffTimeReal')}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <SelectWithLabel
            label="Arrival Airport"
            customClass="basic_select mt-1"
            id="select_airport"
            data={airports}
            isReadOnly={!inEdit}
            currentValue={flightInEdit?.arrivalAirportId}
            value="id"
            onChange={(e: any) => updateFlight(e.target.value, 'arrivalAirport')}
            labelOption="civilCode"
            customClassLabel="input_label mt-3"></SelectWithLabel>
          <InputWithLabel
            type="time"
            label="Landing time UTC"
            readonly={!inEdit}
            value={formatMinutesToReadableHour(flightInEdit?.landingTimeUtc)}
            onChange={(e: any) => updateFlight(e.target.value, 'landingTimeUtc')}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <InputWithLabel
            type="time"
            label="Act landing"
            readonly={!inEdit}
            value={formatMinutesToReadableHour(flightInEdit?.landingTimeReal)}
            onChange={(e: any) => updateFlight(e.target.value, 'landingTimeReal')}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <InputWithLabel
            type="time"
            label="Take off time (TLB)"
            readonly={!inEdit}
            value={formatMinutesToReadableHour(flightInEdit?.takeoffTimeTlb)}
            onChange={(e: any) => updateFlight(e.target.value, 'takeoffTimeTlb')}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <InputWithLabel
            type="time"
            label="Landing time (TLB)"
            readonly={!inEdit}
            value={formatMinutesToReadableHour(flightInEdit?.landingTimeTlb)}
            onChange={(e: any) => updateFlight(e.target.value, 'landingTimeTlb')}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <InputWithLabel
            type="time"
            label="Flight time (TLB)"
            readonly={true}
            value={formatMinutesToReadableHour(flightInEdit?.landingTimeTlb - flightInEdit?.takeoffTimeTlb)}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          {inEdit ? (
            <>
              <SelectWithLabel
                label="Delay"
                customClass="basic_select mt-1"
                id="select_delay"
                data={delayType}
                value="id"
                onChange={(e: any) => setCurrentDelay(e.target.value)}
                labelOption="name"
                customClassLabel="input_label mt-3"></SelectWithLabel>
              <InputWithLabel
                type="text"
                label="Remark"
                onChange={(e: any) => setCurrentRemark(e.target.value)}
                isTextArea={true}
                value={currentRemark}
                customClass="basic_text_area mt-1"
                customClassLabel="input_label mt-3"></InputWithLabel>
              <Button onClick={() => addRemark()} label="Add" customClass="basic_button mt-5 mb-5"></Button>
            </>
          ) : (
            <></>
          )}
          <span className="input_label mt-3">Delay(s)</span>
          {delay.map((d: any) => (
            <CardDelay customClass="mt-1" onDelete={() => deleteDelay(d.id)} remark={d.remark} type={d?.delayType?.name}></CardDelay>
          ))}
          <InputWithLabel
            type="text"
            readonly={!inEdit}
            label="Fuel departure (L)"
            onChange={(e: any) => updateFlight(e.target.value, 'fuelOnDeparture')}
            value={flightInEdit?.fuelOnDeparture}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-5"></InputWithLabel>
          <InputWithLabel
            type="text"
            readonly={!inEdit}
            label="Fuel arrival (L)"
            onChange={(e: any) => updateFlight(e.target.value, 'fuelOnArrival')}
            value={flightInEdit?.fuelOnArrival}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <SelectWithLabel
            label="Flight status"
            customClass="basic_select mt-1"
            id="select_flight_status"
            data={flightStatus}
            value="id"
            isReadOnly={!inEdit}
            currentValue={flightInEdit?.flightStatusId}
            onChange={(e: any) => updateFlight(e.target.value, 'flightStatus')}
            labelOption="name"
            customClassLabel="input_label mt-3"></SelectWithLabel>
          <InputWithLabel
            type="number"
            readonly={!inEdit}
            label="PAX planned"
            onChange={(e: any) => updateFlight(e.target.value, 'passengerPlanned')}
            value={flightInEdit?.passengerPlanned}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <InputWithLabel
            type="number"
            readonly={!inEdit}
            label="PAX"
            onChange={(e: any) => updateFlight(e.target.value, 'passengerCounter')}
            value={flightInEdit?.passengerCounter}
            customClass="basic_input mt-1"
            customClassLabel="input_label mt-3"></InputWithLabel>
          <SelectWithLabel
            label="Mission"
            customClass="basic_select mt-1"
            id="select_mission"
            data={missions}
            isReadOnly={!inEdit}
            value="id"
            currentValue={flightInEdit?.missionId}
            onChange={(e: any) => updateFlight(e.target.value, 'mission')}
            labelOption="name"
            customClassLabel="input_label mt-3"></SelectWithLabel>
          <SelectWithLabel
            label="Aircraft"
            customClass="basic_select mt-1"
            id="select_aircraft"
            data={aircrafts}
            isReadOnly={!inEdit}
            currentValue={flightInEdit?.aircraftId}
            value="id"
            onChange={(e: any) => updateFlight(e.target.value, 'aircraft')}
            labelOption="reg"
            customClassLabel="input_label mt-3"></SelectWithLabel>
          <InputWithLabel
            type="number"
            label="Passenger capacity"
            value={flightInEdit?.passengerCapacity}
            readonly={true}
            min={0}
            // onChange={(e: any) => updateFlight(e.target.value, "passengerCapacity")}
            customClass="basic_input mt-1 mb-10"
            customClassLabel="input_label mt-3"></InputWithLabel>
          {!inEdit ? (
            <FloatingButton
              customClass={'background_blue'}
              icon="img/edit_icon.svg"
              onClick={() => {
                setInEdit(true);
              }}></FloatingButton>
          ) : (
            <FloatingButton customClass={'background_green'} icon="img/save_icon.svg" onClick={() => saveChange()}></FloatingButton>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DispatchForm;
