import { getToken } from '../Context/User.context';
import API from './index';
import { getCookie } from './Utils.service';

export const login = (creds: any) => API.post('/auth/signin', creds);

export const requestPassword = (email: any) => API.post(`/user/resetPassword/stepToken`, email);

export const refreshToken  = () => API.post('/auth/refresh-token', {refreshToken: getCookie("refreshToken"), email: getCookie("userMail")})

export const resetPassword = (data: any) => API.post(`/user/resetPassword/stepChange`, data);

export const get = () => API.get('/user');