import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../generic/Button.generic';
import InputWithLabel from '../../generic/InputWithLabel.generic';
import SelectWithLabel from '../../generic/SelectWithLabel.generic';
import { getAircraft } from '../../services/Aircraft.service';
import { getAirports } from '../../services/Airports.service';
import { add, getFlightStatus } from '../../services/Flight.service';
import { getMissions } from '../../services/Mission.service';
import { formatMinutesToReadableHour, formatReadableHourToMinutes, showErrorToast, showSuccessToast } from '../../services/Utils.service';

const AddFlightForm = () => {
  const [flight, setFlight] = useState<any>({});
  const [airports, setAirports] = useState<any>([]);
  const [missions, setMissions] = useState<any>([]);
  const [aircrafts, setAircrafts] = useState<any>([]);
  const [flightStatus, setFlightStatus] = useState<any>([]);
  const navigate = useNavigate();

  const updateFlight = (value: any, field: string) => {
    switch (field) {
      case 'date':
        flight.date = value;
        break;
      case 'takeoff':
        flight.takeoffTimeUtc = formatReadableHourToMinutes(value);
        break;
      case 'landing':
        flight.landingTimeUtc = formatReadableHourToMinutes(value);
        break;
      case 'passengerCapacity':
        flight.passengerCapacity = value;
        break;
      case 'remark':
        flight.remark = value;
        break;
      case 'departureAirport':
        flight.departureAirportId = +value;
        break;
      case 'arrivalAirport':
        flight.arrivalAirportId = +value;
        break;
      case 'mission':
        flight.missionId = +value;
        break;
      case 'aircraft':
        flight.aircraftId = +value;
        flight.passengerCapacity = aircrafts.filter((a: any) => a.id == value)[0].passengerCapacity;
        break;
      case 'flightStatus':
        flight.flightStatusId = +value;
        break;
      default:
        break;
    }
    setFlight(JSON.parse(JSON.stringify(flight)));
  };

  useEffect(() => {
    getAirports().then(
      (result: any) => {
        setAirports(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
    getMissions().then(
      (result: any) => {
        setMissions(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
    getAircraft().then(
      (result: any) => {
        setAircrafts(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
    getFlightStatus().then(
      (result: any) => {
        setFlightStatus(result?.data);
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
  }, []);

  const createFlight = () => {
    add(flight).then(
      (result: any) => {
        showSuccessToast('Flight has been created');
        navigate('/flights');
      },
      (error: any) => {
        showErrorToast(`${error.response.data.error} - ${error.response.data.message}`);
      },
    );
  };

  return (
    <div className="form_details">
      <InputWithLabel
        type="date"
        label="Date"
        value={flight?.date}
        onChange={(e: any) => updateFlight(e.target.value, 'date')}
        customClass="basic_input mt-1"
        customClassLabel="input_label mt-3"></InputWithLabel>
      <InputWithLabel
        type="time"
        label="Take off time UTC"
        value={formatMinutesToReadableHour(flight?.takeoffTimeUtc)}
        onChange={(e: any) => updateFlight(e.target.value, 'takeoff')}
        customClass="basic_input mt-1"
        customClassLabel="input_label mt-3"></InputWithLabel>
      <InputWithLabel
        type="time"
        label="Landing time UTC"
        value={formatMinutesToReadableHour(flight?.landingTimeUtc)}
        onChange={(e: any) => updateFlight(e.target.value, 'landing')}
        customClass="basic_input mt-1"
        customClassLabel="input_label mt-3"></InputWithLabel>
      <InputWithLabel
        type="number"
        label="Passenger capacity"
        value={flight?.passengerCapacity}
        min={0}
        // onChange={(e: any) => updateFlight(e.target.value, "passengerCapacity")}
        customClass="basic_input mt-1"
        customClassLabel="input_label mt-3"></InputWithLabel>
      <SelectWithLabel
        label="Flight status"
        customClass="basic_select mt-1"
        id="select_flight_status"
        data={flightStatus}
        value="id"
        onChange={(e: any) => updateFlight(e.target.value, 'flightStatus')}
        labelOption="name"
        customClassLabel="input_label mt-3"></SelectWithLabel>
      <InputWithLabel
        type="text"
        label="Remark"
        onChange={(e: any) => updateFlight(e.target.value, 'remark')}
        isTextArea={true}
        value={flight?.remark}
        customClass="basic_text_area mt-1"
        customClassLabel="input_label mt-3"></InputWithLabel>
      <SelectWithLabel
        label="Departure Airport"
        customClass="basic_select mt-1"
        id="select_airport"
        data={airports}
        value="id"
        onChange={(e: any) => updateFlight(e.target.value, 'departureAirport')}
        labelOption="civilCode"
        customClassLabel="input_label mt-3"></SelectWithLabel>
      <SelectWithLabel
        label="Arrival Airport"
        customClass="basic_select mt-1"
        id="select_airport"
        data={airports}
        value="id"
        onChange={(e: any) => updateFlight(e.target.value, 'arrivalAirport')}
        labelOption="civilCode"
        customClassLabel="input_label mt-3"></SelectWithLabel>
      <SelectWithLabel
        label="Mission"
        customClass="basic_select mt-1"
        id="select_mission"
        data={missions}
        value="id"
        onChange={(e: any) => updateFlight(e.target.value, 'mission')}
        labelOption="name"
        customClassLabel="input_label mt-3"></SelectWithLabel>
      <SelectWithLabel
        label="Aircraft"
        customClass="basic_select mt-1"
        id="select_aircraft"
        data={aircrafts}
        value="id"
        onChange={(e: any) => updateFlight(e.target.value, 'aircraft')}
        labelOption="reg"
        customClassLabel="input_label mt-3"></SelectWithLabel>
      <Button onClick={() => createFlight()} label="Create" customClass="basic_button mt-5 mb-5"></Button>
    </div>
  );
};

export default AddFlightForm;
