import { getToken } from '../Context/User.context';
import API from './index';

export const getFlights = () => API.get('/flight', {headers: { "Authorization": `Bearer ${getToken()}`}});

export const getByDate = (start: Date, end: Date) => API.get(`/flight/findByDate?startDate=${start}&endDate=${end}`, {headers: { "Authorization": `Bearer ${getToken()}`}});

export const getFlightById = (flightId: number) => API.get(`/flight/${flightId}`, {headers: { "Authorization": `Bearer ${getToken()}`}});

export const edit = (flight: any, id: any) => API.patch(`/flight/${id}`, flight, {headers: { "Authorization": `Bearer ${getToken()}`}});

export const getFlightStatus = () => API.get('/flightStatus', {headers: { "Authorization": `Bearer ${getToken()}`}});

export const add = (flight: any) => API.post('/flight', flight, {headers: { "Authorization": `Bearer ${getToken()}`}});



