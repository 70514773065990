import React from 'react';

type Props = {
  label?: string;
  onClick?: any;
  customClass?: string;
};

const Link = (props: Props) => {
  return (
    <label className={props.customClass} onClick={props.onClick}>
      {props.label}
    </label>
  );
};

export default Link;
